import React from "react"
import styles from "../../css/banner.module.css"
//import { useBreakpoint } from "gatsby-plugin-breakpoints"
//import { withBreakpoints } from 'gatsby-plugin-breakpoints/withBreakpoints'

const BannerHome = ({ intro, title, info, children }) => {
    
    return (
        <div >
            <div className={styles.gridcontainer}>
                <div className={styles.griditem}>
                    <p>{intro}</p>
                    <h1>{title}</h1>
                    <p>{info}</p>
                    {children}
                    
                    
                </div>
             {/*  <div className={styles.griditem}>
                  
    </div>*/}
                
            </div>
        </div>
    )
}

export default BannerHome
