import React from "react"
import styles from "../../css/styledHero.module.css"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"

const StyledHero = ({ children }) => {
    const {
        homeImage,
        homeImageTablet,
        homeImageFull,
        homeImage4k,
    } = useStaticQuery(graphql`
        query {
            homeImage: file(relativePath: { eq: "home-header-768-cro.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 768, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            homeImageTablet: file(relativePath: { eq: "home-header-1024.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1024) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            homeImageFull: file(relativePath: { eq: "home-header-1440.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            homeImage4k: file(relativePath: { eq: "home-header.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const sources = [
        homeImage.childImageSharp.fluid,
        {
            ...homeImageTablet.childImageSharp.fluid,
            media: `(min-width: 750px) and (max-width: 1000px)`,
        },
        {
            ...homeImageFull.childImageSharp.fluid,
            media: `(min-width: 1001px) and (max-width: 2000px)`,
        },
        {
            ...homeImage4k.childImageSharp.fluid,
            media: `(min-width: 2001px)`,
        },
    ]

    return (
        <BackgroundImage
            Tag={`header`}
            className={styles.headerHome}
            fluid={sources}
        >
            {children}
        </BackgroundImage>
    )
}
export default StyledHero

