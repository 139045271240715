import React, { Suspense, lazy } from "react"
import PrimaryLayout from "../layouts/PrimaryLayout"
import SEO from "../components/Common/SEO"
import StyledHero from "../components/Common/StyledHero"
import BannerHome from "../components/Common/BannerHome"
//import { Link } from "gatsby"
//import About from "../components/Home/About"

//import Servicios from "../components/Home/FeaturedServicios"
//import Segmentos from "../components/Home/FeaturedSegmentos"
//import Estrategias from "../components/Home/Estrategias"

const Segmentos = lazy(() => import("../components/Home/FeaturedSegmentos"))
const Servicios = lazy(() => import("../components/Home/FeaturedServicios"))
const Estrategias = lazy(() => import("../components/Home/Estrategias"))
const About = lazy(() => import("../components/Home/About"))


    const isSSR = typeof window === "undefined"

export default ({ data }) => (
    <PrimaryLayout>
        <SEO
            title="Consultor SEO Eduardo Asenjo"
            description="Posicionamiento web en buscadores. Invierte en tu negocio y consigue más clientes y beneficios. Pide información."
            index="index"
        />
        <StyledHero>
            <BannerHome
                intro="Marketing Online empresarial"
                title="Posicionamiento Web. SEO "
                info=" Invierte en tu negocio y alcanza de manera natural la reputación y el reclamo que tu negocio necesita con tráfico orgánico desde buscadores."
            >
                {/*<Link to="/" className="btn-white">
                    Consultoría SEO
                </Link>*/}
                <span className="btn-white">Consultoría SEO</span>
            </BannerHome>
        </StyledHero>
        <>
      {!isSSR && (
        <Suspense fallback="">
            <Servicios />
            <Estrategias />
            <Segmentos />
            <About />
        </Suspense>
        )}
        </>
    </PrimaryLayout>
)
